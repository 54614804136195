import React from 'react';
import { Trans } from 'react-i18next';
import OrderNowButton from './OrderNowButton';

const roadmapItems = [
  {
    title: <Trans>Marketplace for Tarochi Monsters</Trans>,
    iconUrl: '/images/paima-tarochi-roadmap-1.png'
  },
  {
    title: <Trans>Interoperability with L1 & L2s</Trans>,
    iconUrl: '/images/paima-tarochi-roadmap-2.png'
  },
  {
    title: <Trans>Expansion of the world</Trans>,
    iconUrl: '/images/paima-tarochi-roadmap-3.png'
  }
];

const Roadmap = () => {
  return (
    <section
      id="roadmap"
      className="relative flex flex-col items-center text-white text-32 md:text-40 lg:text-48 py-24 sm:py-32 md:py-64 mx-16 "
    >
      <div className="flex flex-col max-w-[1000px] items-center text-center gap-48">
        <h3 className="font-heading font-bold">
          <Trans>Roadmap</Trans>
        </h3>
        <div className='text-16'>
          <p>
            <Trans>
              Tarochi is already a complete game in its current form, but we a lot planned to continue growing the autonomous world of Tarochi.
              Unlike other games, Tarochi is fully decentralized and anybody can build tools on top of the Tarochi world or propose changes to the game rule.
              It is up to users of the game to decide the canonical game rules, and different sets of game rules will share the global <i>Genesis Trainer</i> NFT collection as well as possibly monsters and other assets like <i>Tarochi Gold</i>.
              Our goal at Paima Studios will be to foster a community around the development of this world and help increase the connectedness and liquidity of the assets of these worlds with the rest of the crypto ecosystem.
            </Trans>
            <br /><br />
            <Trans>
              Our team has spent a lot of time making the modifications and deployment of the base game as easy as possible, and will be releasing all the code for the game state machine, game node API and all other software for Tarochi.
              This will enable anybody to easily create new clients with different UIs and features, new tools like explorers to visualize the game world, propose changes to the game rules and more.
              Importantly, it means unlike web2 games, the autonomous world of Tarochi can live on forever as all the data for its reconstructions lives onchain, and anybody can self-host the game locally making the MMO still playable as long as a single fan exists.
              You can learn more about the technology that power this <a href="https://docs.paimastudios.com/"><u>here</u></a>.
            </Trans>
          </p>
        </div>
        <OrderNowButton />
        <div className="flex flex-col md:flex-row gap-32">
          {roadmapItems.map((item, index) => (
            <div key={index} className="tarochi-blur-box">
              <div className="flex flex-col items-center justify-between h-full gap-8 text-16">
                <img src={item.iconUrl} alt="" className="h-[62px]" />
                <p className="font-bold text-[20px] leading-[24px]">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
