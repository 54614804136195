import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import ReactModal from 'react-modal';
import { useWeb3Context } from '../../../hooks/useWeb3Context';
import { TAROCHI_SALE_WHITELIST_END } from '../../../services/constants';
import { TarochiChain } from '../../../services/contract';
import whitelistAddresses from '../../../services/tarochiSeasonPassWhitelist';
import VolcaneersButton from '../volcaneers/VolcaneersButton';
import { modalStyles } from './OrderNowButton';
import { isMetaMaskInstalled } from '../SimpleContent';

type ChainData = {
  name: string;
  icon: string;
  chainId: number;
  explorerTxUrl: string;
  startBlock?: number;
  offset: number;
};

const chainsMainnet: Record<TarochiChain, ChainData> = {
  xai: {
    name: 'Xai',
    icon: '/images/chain-xai.png',
    chainId: 660279,
    explorerTxUrl: 'https://explorer.xai-chain.net/tx',
    startBlock: 511263 + 1, // was initially 436591
    offset: 1664,
  },
  arbitrum: {
    name: 'Arbitrum',
    icon: '/images/chain-arbitrum.png',
    chainId: 42161,
    explorerTxUrl: 'https://arbiscan.io/tx',
    startBlock: 179447453 + 1, // was initially 178712562
    offset: 4518,
  },
  cardano: {
    name: 'Cardano',
    icon: '/images/chain-cardano.png',
    chainId: -1,
    explorerTxUrl: '',
    offset: 0,
  }
};

// @ts-ignore
const chainsTestnet: Record<TarochiChain, ChainData> = {
  xai: {
    name: 'Xai (Goerli)',
    icon: '/images/chain-xai.png',
    chainId: 47279324479,
    explorerTxUrl: 'https://testnet-explorer.xai-chain.net/tx',
    offset: 0,
  },
  arbitrum: {
    name: 'Arbitrum (Sepolia)',
    icon: '/images/chain-arbitrum.png',
    chainId: 421614,
    explorerTxUrl: 'https://sepolia.arbiscan.io/tx',
    offset: 0,
  },
  cardano: {
    name: 'Cardano (Devnet)',
    icon: '/images/chain-cardano.png',
    chainId: -1,
    explorerTxUrl: '',
    offset: 0,
  }
};

export const chains = process.env.NODE_ENV === 'development' ? chainsTestnet : chainsMainnet;

type Props = {
  continueStep: (selectedChain: TarochiChain) => void;
};

const ChainSelection = ({ continueStep }: Props) => {
  const [isMetamaskModalOpen, setIsMetamaskModalOpen] = useState(false);
  const [isRabbyModalOpen, setIsRabbyModalOpen] = useState(false);
  const { connected, connectWallet, chainId, switchNetworkAsync, currentAccount } = useWeb3Context();

  async function handleClickChain(chain: TarochiChain) {
    const chainData = chains[chain];
    try {
      if (chain !== TarochiChain.cardano) {
        // we check for MetaMask explicitly because we're using the MetaMaskConnector for the wagmi config
        if (!isMetaMaskInstalled) {
          setIsMetamaskModalOpen(true);
          return;
        }
        if (window?.ethereum?.isRabby) {
          setIsRabbyModalOpen(true);
          return;
        }
        if (!connected) {
          await connectWallet();
          return;
        }
        const whitelistOnly =
          new Date().getTime() < TAROCHI_SALE_WHITELIST_END && !whitelistAddresses.includes(currentAccount);
        if (whitelistOnly) {
          alert('You are not on the whitelist!');
          return;
        }
        if (chainId !== chainData.chainId) {
          if (switchNetworkAsync) {
            await switchNetworkAsync(chainData.chainId);
          } else {
            alert(`Please switch the network to ${chainData.name}`);
            return;
          }
        }
      }
      continueStep(chain);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="flex flex-col text-white font-heading text-center">
      <p className="font-semibold text-28 sm:text-32 md:text-40">
        <Trans>Choose your chain</Trans>
      </p>
      <p className="text-14 sm:text-18 md:text-24">
        <Trans>(all chains will be treated equally)</Trans>
      </p>
      <div className="flex flex-col sm:flex-row gap-40 justify-center mt-20 mb-28">
        {(Object.keys(chains) as TarochiChain[]).map((chainKey) => {
          const chain = chains[chainKey];
          return (
            <div
              key={chain.name}
              className="flex flex-col p-20 gap-16 items-center cursor-pointer hover:bg-opacity-10 hover:bg-engine-gray rounded-2xl"
              onClick={() => {
                handleClickChain(chainKey);
              }}
            >
              <img src={chain.icon} className="w-[100px] md:w-[160px]" />
              <p className="text-20 sm:text-32 md:text-40 font-bold">{chain.name}</p>
            </div>
          );
        })}
      </div>
      <ReactModal
        isOpen={isMetamaskModalOpen}
        onRequestClose={() => {
          setIsMetamaskModalOpen(false);
        }}
        style={modalStyles}
        contentLabel="Install Metamask Modal"
        ariaHideApp={false}
      >
        <div className="flex flex-col gap-16 text-white font-heading text-center">
          <p className="text-white">
            <Trans>You need to have the MetaMask extension installed in your browser to connect your wallet.</Trans>
          </p>
          <a
            href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
            target="_blank"
            rel="noreferrer"
          >
            <VolcaneersButton>Install MetaMask Extension</VolcaneersButton>
          </a>
        </div>
      </ReactModal>
      <ReactModal
        isOpen={isRabbyModalOpen}
        onRequestClose={() => {
          setIsRabbyModalOpen(false);
        }}
        style={modalStyles}
        contentLabel="Install Metamask Modal"
        ariaHideApp={false}
      >
        <div className="flex flex-col gap-16 text-white font-heading text-center">
          <p className="text-white">
            <Trans>Rabby is having issues with our mint page. Please use a different wallet such as MetaMask.</Trans>
          </p>
          <a
            href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
            target="_blank"
            rel="noreferrer"
          >
            <VolcaneersButton>Install MetaMask Extension</VolcaneersButton>
          </a>
        </div>
      </ReactModal>
    </div>
  );
};

export default ChainSelection;
