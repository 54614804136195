import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

const CloseIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path d="M6 16C6 15.1716 6.67157 14.5 7.5 14.5H24.5C25.3284 14.5 26 15.1716 26 16C26 16.8284 25.3284 17.5 24.5 17.5H7.5C6.67157 17.5 6 16.8284 6 16Z" />
    </g>
  </svg>
);

const OpenIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7.5C17.5 6.67157 16.8284 6 16 6C15.1716 6 14.5 6.67157 14.5 7.5V14.5H7.5C6.67157 14.5 6 15.1716 6 16C6 16.8284 6.67157 17.5 7.5 17.5H14.5V24.5C14.5 25.3284 15.1716 26 16 26C16.8284 26 17.5 25.3284 17.5 24.5V17.5H24.5C25.3284 17.5 26 16.8284 26 16C26 15.1716 25.3284 14.5 24.5 14.5H17.5V7.5Z"
      />
    </g>
  </svg>
);

interface FAQQuestionProps {
  question: string | React.ReactElement;
  answer: string | React.ReactElement;
}

const FAQQuestion = ({ question, answer }: FAQQuestionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className="td-glass-bg p-16 pl-24 rounded-[32px] text-white">
      <div
        className="flex items-center justify-between w-full"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Toggle question"
        role="button"
      >
        <h3 className="font-bold text-20 leading-8 md:flex-1 text-left">{question}</h3>
        <div className="ml-8 fill-white">{isOpen ? CloseIcon : OpenIcon}</div>
      </div>
      {isOpen && (
        <div className="pt-16">
          <hr className="text-[#4D5763]" />
          <p className="pt-16  text-16 leading-7">{answer}</p>
        </div>
      )}
    </li>
  );
};

interface FAQProps {
  questions: FAQQuestionProps[];
}

const FAQContent = ({ questions }: FAQProps) => {
  return (
    <div className="bg-center bg-cover bg-no-repeat relative">
      <div className="absolute left-0 right-0 top-0 bottom-0 w-full h-full" />
      <div
        id="faq"
        className={classNames(
          'pt-32',
          'pb-32',
          'relative',
          'scroll-mt-[140px]',
          'md:scroll-mt-[130px]',
          'lg:scroll-mt-[110px]'
        )}
      >
        <div className="container-slim relative z-20">
          <h2 className="text-32 xs:text-40 text-white font-bold">
            <Trans>FAQ</Trans>
          </h2>
          <p className="text-white mt-16 max-w-[800px]">
            <Trans>
              Find answers to common questions that other players have asked previously about the game below.
            </Trans>
          </p>
          <ul className="space-y-12 relative z-20 mt-48">
            {questions.map(({ question, answer }, index) => (
              <FAQQuestion question={question} answer={answer} key={index} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const FAQ = () => {
    return (<FAQContent
        questions={[
            {
              question: <Trans>Can I buy multiple Genesis Trainer NFTs?</Trans>,
              answer: <Trans>Yes, you can more than one by just placing a new order each time</Trans>
            },
            {
              question: <Trans>Can I get multiple tiers of benefit at once?</Trans>,
              answer: <Trans>Yes, putting in $21 for example will qualify you for all 3 tiers.</Trans>
            },
            {
              question: <Trans>How can I check orders I've placed?</Trans>,
              answer: <Trans>All orders you've placed are visible onchain, so you can see them from your wallet history. You will receive a soulbound NFT for each order you place.</Trans>
            },
            {
              question: <Trans>When can I expect to receive my order?</Trans>,
              answer: <Trans>Tarochi is going live on mainnet on Feb 15th, so we will start distributing rewards as soon as possible</Trans>
            },
            {
              question: <Trans>Which chain will the order be distributed to?</Trans>,
              answer: <Trans>NFTs will be distributed either on Arbitrum One or Cardano depending on which currency you selected to pay with. Tarochi Gold will be credited to you directly in the game.</Trans>
            },
            {
              question: <Trans>How were whitelist slots handed out?</Trans>,
              answer: <Trans>Whitelist slots were handed out to anybody who connected their wallet during either season 0 or the playtest of Tarochi. You can find the full whitelist <a href="https://docs.google.com/spreadsheets/d/1Qis47yzGr0Ls8gJJUARUP1uH6S9l1RZ_-ctYW4ZFBqI/edit#gid=0"><u>here</u></a></Trans>
            },
            {
              question: <Trans>Is there a cap to how many I can mint as a whitelist holder?</Trans>,
              answer: <Trans>Whitelist holders can only mint at most 2 Genesis Trainer NFTs</Trans>
            },
            {
              question: <Trans>What form of payment do you accept?</Trans>,
              answer: <Trans>XAI, (arb) ETH, (arb) USDC, (arb) USDT, ADA</Trans>
            },
            {
              question: <Trans>Is there a benefit to holding multiple Genesis Trainer NFTs in my wallet?</Trans>,
              answer: <Trans>Although the accelerated progress does not stack, the loyalty rewards and the limited edition monster whitelists do (i.e. 2 genesis trainer NFTs means you get 2 whitelist entries into a limited-edition monster).</Trans>
            },
            {
              question: <Trans>Will I be able to trade my Genesis Trainer on the open market?</Trans>,
              answer: <Trans>Yes, you will be able to buy or sell Genesis Trainer NFTs on any NFT marketplace including OpenSea and jpg.store</Trans>
            },
            {
              question: <Trans>Will I be able to trade my TGOLD on the open market?</Trans>,
              answer: <Trans>Yes, you will be able to buy or sell TGOLD on the open market. TGOLD lives inside Tarochi (you can think of it like a L4) and we will work on facilitating buying & selling TGOLD from Arbitrum One directly.</Trans>
            },
            {
              question: <Trans>Is my TGOLD associated to my wallet or my trainer?</Trans>,
              answer: <Trans>TGOLD will be credited to your address when the mint ends - not your trainer. It means you can buy/sell the trainer without worrying about any effects on your TGOLD.</Trans>
            },
            {
              question: <Trans>How long will a season last?</Trans>,
              answer: <Trans>Tarochi Gold and the Genesis Trainer are permanent. The free mint season pass however is not guaranteed to provide a permanent benefit and we may release a new season in the future (exact date not yet defined)</Trans>
            },
        ]}
      />);
}

export default FAQ;
