import React from 'react';
import { Trans } from 'react-i18next';
import { useWeb3Context } from '../../../hooks/useWeb3Context';
import { TarochiChain } from '../../../services/contract';
import { truncateAddress } from '../../../services/utils';
import CopyableAddress from '../CopyableAddress';
import CopyableLink from '../CopyableLink';
import { chains } from './ChainSelection';
import { Tier } from './TierSelection';

type Props = {
  hash: string;
  chain: TarochiChain;
  tier: Tier;
};

const OrderComplete = ({ hash, chain, tier }: Props) => {
  const { currentAccount } = useWeb3Context();
  console.log(window.location.toString());
  return (
    <div className="flex flex-col text-white font-heading">
      <div className="flex flex-col gap-16 w-full lg:w-[60%] self-center">
        <p className="text-start text-16 md:text-20 lg:text-28">
          <Trans>Transaction hash:</Trans>{' '}
          <a href={`${chains[chain].explorerTxUrl}/${hash}`} className="hover:underline" target="_blank">
            {truncateAddress(hash)}
          </a>
        </p>
        <div className="flex flex-col md:flex-row rounded-xl bg-[#B2BBC7]/10 items-center p-16 gap-16">
          <img
            src={'/images/tarochi-season-1-pass.png'}
            alt={`Tarochi Season 1 Pass`}
            className="w-full h-full max-w-[350px] md:w-[260px] md:h-[257px]"
          />
          <div className="flex h-full items-center text-start text-20 sm:text-32 md:text-40 font-bold px-16">
            <Trans>Tarochi Season 1 Season Pass</Trans>
          </div>
        </div>
      </div>
      {tier !== 'tier1' && (
        <p className="mt-32 text-20 lg:text-28">
          <Trans>If you wish to purchase another Genesis Trainer NFT, please repeat the process.</Trans>
        </p>
      )}
      <div className="h-[1px] bg-primary-color/20 my-40"></div>
      <div className="flex flex-col gap-40 text-16 sm:text-20 md:text-28 lg:text-36">
        <p className="text-start">
          <Trans>When others use your link, you get 5% of their purchase!</Trans>
        </p>
        <div className="flex flex-row gap-32 items-center text-16 sm:text-20 md:text-24 lg:text-30">
          <p>
            <Trans>Referral code</Trans>
          </p>
          <CopyableAddress address={currentAccount} textClasses="text-white text-16 sm:text-20 md:text-24 lg:text-30" />
          <CopyableLink
            href={`${window.location.toString()}?referral=${currentAccount}`}
            textClasses="text-white text-16 sm:text-20 md:text-24 lg:text-30"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderComplete;
