import React from 'react';
import { Trans } from 'react-i18next';
import { Countdown } from '../Countdown';
import { TAROCHI_GENESIS_NFT_MAX_SUPPLY } from '../../../services/constants';

const TarochiTimer = ({ minted, deadline, label }: { minted?: number, deadline: Date; label: string }) => {
  const now = new Date();
  if (now > deadline) {
    return <></>;
  }
  const remaining = TAROCHI_GENESIS_NFT_MAX_SUPPLY - (minted ?? 0);
  console.log(remaining);
  const text = remaining > 0 ? `${remaining} Genesis Trainers remaining` : (
    <span className='font-normal'>Genesis Trainers <b>sold out!</b>
      <br />
      Only <i>TGOLD</i> and <i>free mint</i> remain
      <br />
      Join our <a href="https://discord.gg/QFH9nKSUbd"><u>Discord</u></a> for next steps
    </span>
  );
  return (
    <div className="flex flex-col items-center mt-48 mx-16">
      <div className="tarochi-blur-box max-w-2xl font-heading font-bold text-white text-24 sm:text-28 md:text-32 lg:text-40">
        <div className="flex flex-col gap-8">
          <p className="text-[16px] sm:text-[20px] md:text-[28px] lg:text-[36px]">
            <Trans>{label}</Trans>
          </p>
          <Countdown deadline={deadline} />
          {minted != null && (
            <div className="text-[16px]">
              {text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TarochiTimer;
