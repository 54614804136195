import { useContext } from 'react';

import { TarochiLayoutContext } from '../components/v2/tarochi/TarochiLayout';

export const useTarochiLayoutContext = () => {
  const context = useContext(TarochiLayoutContext);

  if (context === undefined) {
    throw new Error('useTarochiLayoutContext must be used within a TarochiLayoutContext.Provider');
  }

  return context;
};
