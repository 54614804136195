import { useEffect, useState } from 'react';
import { TarochiChain } from '../services/contract';
import { getGenesisMinted } from './useGetTarochiSeasonPassMinted';
import { useWeb3Context } from './useWeb3Context';

export const useGetTarochiSeasonPassUserMinted = (doFetch: boolean) => {
  const [minted, setMinted] = useState<number>();
  const { currentAccount: receiver } = useWeb3Context();

  async function fetchAndSetMinted() {
    if (!doFetch || !receiver) {
      return;
    }
    if (minted != null && document.visibilityState !== 'visible') {
      return;
    }
    const results = await Promise.all([
      getGenesisMinted(TarochiChain.xai, receiver),
      getGenesisMinted(TarochiChain.arbitrum, receiver)
    ]);
    const newMinted = results.reduce((prev, curr) => prev + curr, 0);
    // console.log('user minted', newMinted);
    setMinted(newMinted);
  }

  useEffect(() => {
    fetchAndSetMinted();
  }, []);

  return { minted, refresh: fetchAndSetMinted };
};
