import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';
import DiscordIcon from '../../../../static/images/icon-discord.inline.svg';
import TwitterIcon from '../../../../static/images/icon-twitter.inline.svg';
import CustomLink from '../CustomLink';
import OrderNowButton from './OrderNowButton';

const socialLinks = [
  {
    alt: <Trans>Twitter</Trans>,
    url: 'https://twitter.com/TarochiGame',
    icon: <TwitterIcon />
  },
  {
    alt: <Trans>Discord</Trans>,
    url: 'https://discord.gg/QFH9nKSUbd',
    icon: <DiscordIcon />
  }
];

type Detail = {
  iconUrl: string;
  title: React.ReactNode,
  description: React.ReactNode;
  imageUrl: string;
  imageWidth?: number;
};
type Section = {
  title: React.ReactNode;
  mintCost?: React.ReactNode;
  yellowText?: React.ReactNode;
  nftImage?: string;
  tokenomics?: React.ReactNode;
  supply: React.ReactNode;
  details: Detail[];
}
const sections: Section[] = [
  {
    title: <Trans>Mint has ended</Trans>,
    supply: <Trans>The mint is over. Thank you to all who supported Tarochi early!</Trans>,
    details: [
      {
        iconUrl: '/images/paima-engine-game-tab-icon-boxes.svg',
        title: <Trans>OpenSea</Trans>,
        description: (
          <>
            <p>
              <Trans>
                Genesis Trainer collection: 
              </Trans>
              &nbsp;
              <a href="https://opensea.io/collection/genesis-trainers"><u><Trans>here</Trans></u></a>
            </p>
            <br />
            <p>
              <Trans>
                Monster Trading: 
              </Trans>
              &nbsp;
              <a href="https://opensea.io/collection/tarochi-monsters?search[stringTraits][0][name]=validity&search[stringTraits][0][values][0]=valid"><u><Trans>here</Trans></u></a>
            </p>
            <br />
            <p>
              <Trans>
                TGold Trading: 
              </Trans>
              &nbsp;
              <a href="https://opensea.io/collection/tarochi-gold?search[collections][0]=tarochi-gold&search[stringTraits][0][name]=validity&search[stringTraits][0][values][0]=valid"><u><Trans>here</Trans></u></a>
            </p>
          </>
        ),
        imageUrl: 'https://opensea.io/static/images/logos/opensea-logo.svg',
        imageWidth: 80,
      },
      {
        iconUrl: '/images/paima-engine-game-tab-icon-boxes.svg',
        title: <Trans>jpg.store</Trans>,
        description: (
          <>
            <p>
              <Trans>
                You can find the Genesis Trainer collection on jpg.store
              </Trans>
              &nbsp;
              <a href="https://www.jpg.store/collection/5863348a43603dc32f2869e2418ced34d01deb5a36154488caedcf07"><u><Trans>here</Trans></u></a>
            </p>
          </>
        ),
        imageUrl: 'https://avatars.githubusercontent.com/u/98781883?s=200&v=4',
        imageWidth: 80,
      },
    ]
  },
  {
    title: <Trans>Free mint benefits</Trans>,
    supply: <Trans>Unlimited supply</Trans>,
    mintCost: <Trans>Free mint</Trans>,
    yellowText: <Trans>Limited Time Soulbound NFT</Trans>,
    nftImage: '/images/tarochi-season-1-pass.png',
    details: [
      {
        iconUrl: '/images/paima-engine-game-tab-icon-boxes.svg',
        title: <Trans>Address included in holder list of Tarochi (onchain)</Trans>,
        description: (
          <>
            <p>
              <Trans>
                Holding on to an NFT will allow any person or smart contract to easily reference the holder list in the
                future for any purposes.
              </Trans>
            </p>
            <br />
            <p>
              <Trans>
                Be it for Tarochi, other games in the Paima ecosystem in general, or other games deploying on Xai.
              </Trans>
            </p>
          </>
        ),
        imageUrl: '/images/paima-tarochi-season-pass-3.png'
      },
      {
        iconUrl: '/images/paima-engine-game-tab-icon-physics.svg',
        title: <Trans>Early access to upcoming features</Trans>,
        description: (
          <>
            <p>
              <Trans>Tarochi will continuously receive updates from our teams.</Trans>
            </p>
            <br />
            <p>
              <Trans>
                Get access to these features ahead of time, and also be included in playtest of any future Paima game.
              </Trans>
            </p>
          </>
        ),
        imageUrl: '/images/paima-tarochi-season-pass-1.png'
      }
    ]
  },
  {
    title: <Trans>Genesis Trainer Benefits</Trans>,
    supply: <Trans>10k max supply</Trans>,
    mintCost: <Trans>$20 mint</Trans>,
    yellowText: <Trans>Limited Time Tradeable NFT</Trans>,
    nftImage: '/images/paima-tarochi-genesis-nft.png',
    tokenomics: (
      <>
        <p>
          <Trans>999 for treasury</Trans>
        </p>
        <p>
          <Trans>6.9% royalty</Trans>
        </p>
      </>
    ),
    details: [
      {
        iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
        title: <Trans>Whitelisted for limited-edition monsters</Trans>,
        description: (
          <>
            <p>
              <Trans>
                Tarochi will partner with many NFT collections and projects for limited-edition rare monsters to catch.
              </Trans>
            </p>
            <br />
            <div>
              <Trans>These monsters will be:</Trans>
              <ul className="list-disc ml-24">
                <li>
                  <Trans>Free to catch for collection holders</Trans>
                </li>
                <li>
                  <Trans
                    i18nKey="Free to catch for Genesis Trainer holders"
                    components={{
                      strong: <strong />,
                      em: <em />
                    }}
                  />
                </li>
                <li>
                  <Trans>Pay to catch for other players (see tokenomics below for more info)</Trans>
                </li>
              </ul>
            </div>
          </>
        ),
        imageUrl: '/images/paima-tarochi-season-pass-2.png'
      },
      {
        iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
        title: <Trans>Loyalty Rewards</Trans>,
        description: (
          <>
            <p>
              <Trans
                i18nKey="Receive a Legendary Whitelist Capture Card for every 30 days you do not sell the NFT for the first 120 days after launch"
                components={{
                  strong: <strong />,
                  em: <em />
                }}
              />
            </p>
          </>
        ),
        imageUrl: '/images/paima-tarochi-season-pass-4.png'
      },
      {
        iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
        title: <Trans>Accelerated progress</Trans>,
        description: (
          <>
            <p>
              <Trans
                i18nKey="Receive in-game accelerated progress including a higher XP rate, a higher base catch chance and a higher chance of successfully fleeing among other benefits."
                components={{
                  strong: <strong />,
                  em: <em />
                }}
              />
            </p>
          </>
        ),
        imageUrl: '/images/paima-tarochi-season-pass-5.png'
      }
    ]
  },
  {
    title: <Trans>Genesis Trainer+ Benefits</Trans>,
    supply: <Trans>Game-limited supply</Trans>,
    mintCost: <Trans>No limit</Trans>,
    details: [
      {
        iconUrl: '/images/paima-engine-game-tab-icon-physics.svg',
        title: <Trans>Extra Starting Tarochi Gold ($TGOLD)</Trans>,
        description: (
          <>
            <p>
              <Trans
                i18nKey="For every $1 over the Genesis Trainer Benefit, you will get 2560 Tarochi Gold which can be used to purchase future limited-edition capture cards (see tokenomics for more) and is tradeable on the open market"
                components={{
                  strong: <strong />,
                  em: <em />
                }}
              />
            </p>
          </>
        ),
        imageUrl: '/images/paima-tarochi-season-pass-6.png'
      }
    ]
  }
];
const SeasonPassInfo = () => {
  return (
    <section id="season-pass" className="relative flex flex-col items-center py-24 sm:py-32 lg:py-64 mx-16">
      <div className="tarochi-blur-box max-w-7xl">
        <div className="flex flex-col lg:flex-row mb-64 lg:justify-between">
          <div className="flex flex-col max-w-[575px]">
            <div className="flex gap-16 items-center">
              <img src={'/images/tarochi.webp'} alt={`Tarochi logo`} className="max-w-[88px] max-h-[88px]" />
              <p className="font-heading text-24 sm:text-28 md:text-40 font-bold text-white text-start">
                <Trans>Tarochi season pass</Trans>
              </p>
            </div>
            <div className="mt-48 text-engine-gray flex flex-col items-start text-start">
              <p>
                <Trans>
                  Tarochi is the first game coming out on Xai (Arbitrum) with a release date of February 16th.
                </Trans>
              </p>
              <br />
              <p>
                <Trans>
                  With a free-to-play model, Tarochi provides an easy way for users to fall in love with the game before deciding to participate in the tokenomics.
                </Trans>
              </p>
              <br />
              <p>
                <Trans>
                  Tarochi is a fully decentralized autonomous world, allowing not just our team, but anybody to
                  contribute to the game world and ecosystem.
                </Trans>
              </p>
              <br />
            </div>
            <div className="flex flex-col flex-grow items-stretch justify-end md:items-start mt-16 lg:mt-0">
              <OrderNowButton />
            </div>
          </div>
          <div className="flex flex-col flex-grow gap-24 justify-center items-center mt-16">
            <div className="relative h-fit">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/7w3TWW_uOIM?si=FK46UICNjOqsLb_l"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
            <div className="flex gap-16">
              {socialLinks.map(({ alt, icon, url }, index) => (
                <CustomLink
                  key={index}
                  to={url}
                  className="text-[#B3B8B6] text-14 font-semibold leading-4 flex items-center justify-center md:justify-start hover:text-white group"
                >
                  {icon && (
                    <div className="w-[24px] h-[24px] inline-block mr-8 fill-[#48524E] group-hover:fill-volcaneers-primary-default">
                      {icon}
                    </div>
                  )}
                </CustomLink>
              ))}
            </div>
          </div>
        </div>

        {sections.map((section, index) => (
          <div key={index} className="flex flex-col mt-64">
            <div className="flex gap-16 items-center text-white">
              <div className="h-[1px] bg-white flex-1" />
              <p className="font-tarochi font-medium text-30 sm:text-40 md:text-60">{section.title}</p>
              <div className="h-[1px] bg-white flex-1" />
            </div>
            <div className="flex flex-col-reverse lg:flex-row mt-48">
              <div className="flex flex-col grow gap-64 lg:gap-128 my-0 lg:my-64 text-start">
                {section.details.map((detail, index) => (
                  <div key={index} className="flex flex-col md:flex-row gap-60">
                    <div className="flex md:block justify-center md:w-[200px] flex-shrink-0">
                      <img src={detail.imageUrl} alt="" className={`w-[${detail.imageWidth ?? 200}px] mx-auto h-auto place-self-center`} />
                    </div>
                    <div className="flex flex-col">
                      <h3 className="flex items-start gap-8 text-20 leading-6 text-white font-bold">
                        <img src={detail.iconUrl} alt="" className="w-[24px] h-[24px] relative" />
                        {detail.title}
                      </h3>
                      <div className="text-engine-gray mt-24 text-start">{detail.description}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="h-[1px] lg:h-auto w-auto lg:w-[1px] my-16 lg:my-0 mx-0 lg:mx-24 border-white border-t-[1px] border-l-[1px] border-dashed mb-32" />
              <div
                className={classNames(
                  'flex lg:flex-col flex-shrink-0 justify-between lg:justify-center lg:w-[270px] text-start text-white font-tarochi uppercase leading-10 text-[32px] sm:text-[36px] md:text-[40px] lg:text-[48px]',
                  !section.nftImage ? 'justify-center' : ''
                )}
              >
                <div className="flex flex-col ">
                  <p>{section.supply}</p>
                  <p>{section.mintCost}</p>
                </div>
                {(section.nftImage || section.yellowText) && (
                  <div className="relative h-full flex items-center mb-80 lg:mb-0">
                    {section.nftImage && (
                      <img
                        src={section.nftImage}
                        alt={`Tarochi Season 1 Pass`}
                        className="w-full h-auto max-w-[350px]"
                      />
                    )}
                    {section.yellowText && (
                      <p className="font-tarochi font-medium -rotate-12 text-[#fff700] uppercase absolute top-[50%] mt-40 sm:mt-80 ml-32 leading-5 sm:leading-7 md:leading-10 text-[24px] md:text-[40px] lg:text-[48px]">
                        {section.yellowText}
                      </p>
                    )}
                  </div>
                )}
                {section.tokenomics && (
                  <div className="leading-[26px] text-[16px] sm:text-[20px] md:text-[28px] lg:text-[36px]">
                    {section.tokenomics}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SeasonPassInfo;
