import axios from 'axios';
import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { chains } from '../components/v2/tarochi/ChainSelection';
import { currencies } from '../components/v2/tarochi/CurrencySelection';
import { ADDRESS_ZERO } from '../services/constants';
import { getTarochiSeasonPassBuyEvents, TarochiChain } from '../services/contract';

async function getGenesisMintedEVM(chain: TarochiChain, receiver?: string) {
  if (receiver == null) return 10000;
  let startBlock = chains[chain].startBlock;
  if (startBlock == null) {
    startBlock = 0;
  }
  try {
    const events = await getTarochiSeasonPassBuyEvents(chain, startBlock, receiver);
    const chainCurrencies: Record<string, { minPriceWithoutDiscount: BigNumber; minPriceWithDiscount: BigNumber }> = {};
    currencies[chain].forEach((currency) => {
      chainCurrencies[currency.tokenAddress] = {
        minPriceWithoutDiscount: currency.tier2,
        minPriceWithDiscount: currency.tier2.mul(9).div(10)
      };
    });
    const newEvents = events.filter((event) =>
      event.args.price.gte(
        event.args.referrer === ADDRESS_ZERO
          ? chainCurrencies[event.args.paymentToken].minPriceWithoutDiscount
          : chainCurrencies[event.args.paymentToken].minPriceWithDiscount
      )
    ).length;
    return newEvents + chains[chain].offset;
  } catch (e) {
    throw e;
  }
}

async function getGenesisMintedCardano() {
  return 10000;
  try {
    const response = await axios.get('https://padierfind.pythonanywhere.com/tarochi_endpoint');
    return response.data.totalPurchasesOver40ADA;
  } catch (err) {
    console.error('Unable to get Cardano Genesis Trainer minted amount', err);
    return 341; // approximate number at the end of the sale
  }
}

export async function getGenesisMinted(chain: TarochiChain, receiver?: string) {
  if (chain === TarochiChain.cardano) {
    return await getGenesisMintedCardano();
  } else {
    return await getGenesisMintedEVM(chain, receiver);
  }
}

export const useGetTarochiSeasonPassMinted = () => {
  const [minted, setMinted] = useState<number | undefined>();

  async function fetchAndSetMinted() {
    if (minted != null && document.visibilityState !== 'visible') {
      return;
    }
    const results = await Promise.all([
      getGenesisMinted(TarochiChain.xai),
      getGenesisMinted(TarochiChain.arbitrum),
      getGenesisMinted(TarochiChain.cardano)
    ]);
    const newMinted = results.reduce((prev, curr) => prev + curr, 0);
    // console.log('genesis', newMinted);
    setMinted(newMinted);
  }

  useEffect(() => {
    fetchAndSetMinted();
  }, []);

  return { minted, refresh: fetchAndSetMinted };
};
