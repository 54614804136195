import { BigNumber, ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils.js';
import React from 'react';
import { Trans } from 'react-i18next';
import { useIsTarochiGenesisSoldOut } from '../../../hooks/useIsTarochiGenesisSoldOut';
import { useWeb3Context } from '../../../hooks/useWeb3Context';
import { ADDRESS_ZERO } from '../../../services/constants';
import { TarochiChain } from '../../../services/contract';
import { Spinner } from '../../Spinner';
import { Tier } from './TierSelection';

export type Currency = {
  [tier in Tier]: BigNumber;
} & {
  name: string;
  icon: string;
  tokenAddress: string;
  tokenDecimals: number;
  tokenSymbol: string;
};

// First currency of every chain needs to be the native gas token!
const currenciesMainnet: Record<TarochiChain, Currency[]> = {
  xai: [
    {
      name: 'Xai',
      icon: '/images/chain-xai.png',
      tokenAddress: ADDRESS_ZERO,
      tokenDecimals: 18,
      tokenSymbol: 'XAI',
      tier1: ethers.utils.parseUnits('0', 18),
      tier2: ethers.utils.parseUnits('23.786869', 18),
      tier3: ethers.utils.parseUnits('24.976213', 18)
    }
  ],
  arbitrum: [
    {
      name: 'Ether',
      icon: '/images/token-eth.png',
      tokenAddress: ADDRESS_ZERO,
      tokenDecimals: 18,
      tokenSymbol: 'ETH',
      tier1: ethers.utils.parseUnits('0', 18),
      tier2: ethers.utils.parseUnits('0.008695', 18),
      tier3: ethers.utils.parseUnits('0.009130', 18)
    },
    {
      name: 'USD Coin',
      icon: '/images/token-usdc.png',
      tokenAddress: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
      tokenDecimals: 6,
      tokenSymbol: 'USDC',
      tier1: ethers.utils.parseUnits('0', 6),
      tier2: ethers.utils.parseUnits('20', 6),
      tier3: ethers.utils.parseUnits('21', 6)
    },
    {
      name: 'Tether USD',
      icon: '/images/token-usdt.png',
      tokenAddress: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      tokenDecimals: 6,
      tokenSymbol: 'USDT',
      tier1: ethers.utils.parseUnits('0', 6),
      tier2: ethers.utils.parseUnits('20', 6),
      tier3: ethers.utils.parseUnits('21', 6)
    }
  ],
  cardano: [
    {
      name: 'ADA',
      icon: '',
      tokenAddress: ADDRESS_ZERO,
      tokenDecimals: 6,
      tokenSymbol: 'ADA',
      tier1: ethers.utils.parseUnits('0', 6),
      tier2: ethers.utils.parseUnits('40', 6),
      tier3: ethers.utils.parseUnits('42', 6)
    }
  ]
};

// First currency of every chain needs to be the native gas token!
const currenciesTestnet: Record<TarochiChain, Currency[]> = {
  xai: [
    {
      name: 'Xai',
      icon: '/images/chain-xai.png',
      tokenAddress: ADDRESS_ZERO,
      tokenDecimals: 18,
      tokenSymbol: 'XAI',
      tier1: ethers.utils.parseUnits('0', 18),
      tier2: ethers.utils.parseUnits('0.001', 18),
      tier3: ethers.utils.parseUnits('0.0011', 18)
    },
    {
      name: 'USD Coin',
      icon: '/images/token-usdc.png',
      tokenAddress: '0xF200edFf719b0519eb3ced3cc05802D493A04ca8',
      tokenDecimals: 6,
      tokenSymbol: 'USDC',
      tier1: ethers.utils.parseUnits('0', 6),
      tier2: ethers.utils.parseUnits('20', 6),
      tier3: ethers.utils.parseUnits('21', 6)
    }
  ],
  arbitrum: [
    {
      name: 'Ether',
      icon: '/images/token-eth.png',
      tokenAddress: ADDRESS_ZERO,
      tokenDecimals: 18,
      tokenSymbol: 'ETH',
      tier1: ethers.utils.parseUnits('0', 18),
      tier2: ethers.utils.parseUnits('0.001', 18),
      tier3: ethers.utils.parseUnits('0.0011', 18)
    },
    {
      name: 'USD Coin',
      icon: '/images/token-usdc.png',
      tokenAddress: '0xEa4aa9F8C0Ab419F6C7a79760D9935a41FfaA62E',
      tokenDecimals: 6,
      tokenSymbol: 'USDC',
      tier1: ethers.utils.parseUnits('0', 6),
      tier2: ethers.utils.parseUnits('20', 6),
      tier3: ethers.utils.parseUnits('21', 6)
    }
  ],
  cardano: [
    {
      name: 'ADA',
      icon: '',
      tokenAddress: ADDRESS_ZERO,
      tokenDecimals: 6,
      tokenSymbol: 'ADA',
      tier1: ethers.utils.parseUnits('0', 6),
      tier2: ethers.utils.parseUnits('40', 6),
      tier3: ethers.utils.parseUnits('42', 6)
    }
  ]
};

export const currencies = process.env.NODE_ENV === 'development' ? currenciesTestnet : currenciesMainnet;

type Props = {
  continueStep: (currency: Currency) => void;
  selectedChain: TarochiChain;
  selectedTier: Tier;
};

const CurrencySelection = ({ continueStep, selectedChain, selectedTier }: Props) => {
  const { connected, connectWallet, chainId, switchNetworkAsync } = useWeb3Context();
  const chainCurrencies = currencies[selectedChain];
  const queryParameters = new URLSearchParams(window.location.search);
  const referral = queryParameters.get('referral') ?? '';
  const { soldOut } = useIsTarochiGenesisSoldOut();

  async function handleClickCurrency(currency: Currency) {
    continueStep(currency);
  }
  return (
    <div className="flex flex-col text-white font-heading text-center">
      <p className="font-semibold text-28 sm:text-32 md:text-40">
        <Trans>Choose your currency</Trans>
      </p>
      <p className="text-14 sm:text-18 md:text-24">
        <Trans>(all currencies will be treated equally)</Trans>
      </p>
      <div className="flex flex-col sm:flex-row gap-40 justify-center mt-20 mb-28">
        {chainCurrencies.map((currency) => {
          let price = currency[selectedTier];
          const tier2PriceAfterDiscount = soldOut
            ? BigNumber.from('0')
            : referral.length > 0
            ? currency.tier2.mul(9).div(10)
            : currency.tier2;
          if (selectedTier === 'tier2') {
            price = tier2PriceAfterDiscount;
          } else if (selectedTier === 'tier3') {
            price = currency.tier3.sub(currency.tier2).mul(5).add(tier2PriceAfterDiscount);
          }
          return (
            <div
              key={currency.name}
              className="flex flex-col p-20 gap-16 items-center cursor-pointer hover:bg-opacity-10 hover:bg-engine-gray rounded-2xl"
              onClick={() => {
                handleClickCurrency(currency);
              }}
            >
              <img src={currency.icon} className="w-[100px] md:w-[160px]" />
              <div className="flex flex-col sm:gap-8 md:gap-16">
                <p className="text-20 sm:text-32 md:text-40 font-bold">{currency.name}</p>
                <div className="text-20 sm:text-32 md:text-40">
                  {price != null ? formatUnits(price, currency.tokenDecimals) : <Spinner />} {currency.tokenSymbol}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CurrencySelection;
