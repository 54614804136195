import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';
import { useIsTarochiGenesisSoldOutUser } from '../../../hooks/useIsTarochiGenesisSoldOutUser';
import { useWeb3Context } from '../../../hooks/useWeb3Context';
import { TarochiChain } from '../../../services/contract';
import { formatDollars } from '../../../services/utils';

export type Currency = {
  name: string;
  icon: string;
  tokenAddress: string;
  tokenDecimals: number;
  tokenSymbol: string;
};

export type Tier = 'tier1' | 'tier2' | 'tier3';
type TierData = {
  title: React.JSX.Element;
  details: React.JSX.Element;
  detailsStartIfGenesisNotSoldOut?: React.JSX.Element;
  detailsStartIfGenesisSoldOut?: React.JSX.Element;
  price: number;
};

const tiers: Record<Tier, TierData> = {
  tier1: {
    title: <Trans>Free mint</Trans>,
    details: (
      <>
        {/* <li>
          <Trans>We recommend the Genesis Trainer tier (which includes this tier)</Trans>
        </li> */}
        <li>
          <Trans>Address included in holder list of Tarochi (onchain)</Trans>
        </li>
        <li>
          <Trans>Early access to upcoming features</Trans>
        </li>
      </>
    ),
    price: 0
  },
  tier2: {
    title: <Trans>Genesis Trainer</Trans>,
    detailsStartIfGenesisNotSoldOut: (
      <li>
        <Trans>All of the above</Trans>
      </li>
    ),
    detailsStartIfGenesisSoldOut: (
      <li>
        <Trans>All of the above</Trans>
      </li>
    ),
    details: (
      <>
        <li>
          <Trans>Whitelisted for limited-edition monsters</Trans>
        </li>
        <li>
          <Trans>Loyalty Rewards</Trans>
        </li>
        <li>
          <Trans>Accelerated progress</Trans>
        </li>
      </>
    ),
    price: 20
  },
  tier3: {
    title: <Trans>$TGOLD Pack</Trans>,
    detailsStartIfGenesisNotSoldOut: (
      <li>
        <Trans>All of the above</Trans>
      </li>
    ),
    detailsStartIfGenesisSoldOut: (
      <li>
        <Trans>All of the Free mint tier</Trans>
      </li>
    ),
    details: (
      <>
        <li>
          <Trans>Extra Starting Tarochi Gold ($TGOLD)</Trans>
        </li>
      </>
    ),
    price: 25
  }
};

type Props = {
  continueStep: (tier: Tier) => void;
  genesisSoldOut: boolean;
  chain: TarochiChain;
};

const TierSelection = ({ continueStep, genesisSoldOut, chain }: Props) => {
  const { connected, connectWallet, chainId, switchNetworkAsync } = useWeb3Context();
  const { soldOutUser: genesisPersonalLimitReached } = useIsTarochiGenesisSoldOutUser();
  const queryParameters = new URLSearchParams(window.location.search);
  const referral = queryParameters.get('referral') ?? '';

  async function handleClickTier(tier: Tier) {
    continueStep(tier);
  }
  return (
    <div className="flex flex-col text-white font-heading text-center">
      <p className="font-semibold text-28 sm:text-32 md:text-40">
        <Trans>Choose your tier</Trans>
      </p>
      <div className="flex flex-col divide-y gap-40 justify-center mt-20 mb-28">
        <div />
        {(Object.keys(tiers) as Tier[]).map((tier, index) => {
          const tierData = tiers[tier];
          // recall: free mint not possible on Cardano
          const tierIsSoldOut = (tier === 'tier2' && genesisSoldOut) || (tier === 'tier1' && chain === TarochiChain.cardano);
          const tierLimitReached = tier !== 'tier1' && genesisPersonalLimitReached && chain !== TarochiChain.cardano;
          let tierFullPrice = tierData.price;
          if (genesisSoldOut && tier === 'tier3') {
            tierFullPrice -= tiers['tier2'].price;
          }

          let tierPrice = tierData.price;
          const tier2PriceAfterDiscount = genesisSoldOut
            ? 0
            : referral.length > 0 && chain !== TarochiChain.cardano
            ? (tiers.tier2.price * 9) / 10
            : tiers.tier2.price;
          if (tier === 'tier2') {
            tierPrice = tier2PriceAfterDiscount;
          } else if (tier === 'tier3') {
            tierPrice = tiers.tier3.price - tiers.tier2.price + tier2PriceAfterDiscount;
          }
          return (
            <div
              key={tier}
              className={classNames(
                'flex flex-col p-20 gap-16 items-center cursor-pointer hover:bg-opacity-10 hover:bg-engine-gray rounded-2xl',
                tierIsSoldOut || tierLimitReached ? 'pointer-events-none' : ''
              )}
              onClick={
                tierIsSoldOut || tierLimitReached
                  ? undefined
                  : () => {
                      handleClickTier(tier);
                    }
              }
            >
              <div className="flex flex-col w-full sm:gap-8 md:gap-16">
                <p
                  className={classNames(
                    'text-20 sm:text-32 md:text-40 font-bold',
                    tierIsSoldOut || tierLimitReached ? 'line-through decoration-primary-color-700' : ''
                  )}
                >
                  {tierData.title}
                </p>
                <div className="flex">
                  <div className="text-start flex-grow">
                    <ul className="list-disc ml-24">
                      {genesisSoldOut
                        ? tierData.detailsStartIfGenesisSoldOut
                        : tierData.detailsStartIfGenesisNotSoldOut}
                      {tierData.details}
                    </ul>
                  </div>
                  <div className="flex self-end items-end gap-16">
                    {!(tierIsSoldOut || tierLimitReached) &&
                      referral.length > 0 &&
                      chain !== TarochiChain.cardano &&
                      tierFullPrice !== tierPrice && (
                        <p className="text-16 sm:text-28 md:text-36 line-through decoration-primary-color-700">
                          {formatDollars(tierFullPrice)}
                          {index === Object.keys(tiers).length - 1 ? '+' : ''}
                        </p>
                      )}
                    <p className="text-20 sm:text-32 md:text-40 font-bold">
                      {tierIsSoldOut ? (
                        <Trans>Sold out</Trans>
                      ) : tierLimitReached ? (
                        <Trans>Address limit reached</Trans>
                      ) : (
                        <>
                          {formatDollars(tierPrice)}
                          {index === Object.keys(tiers).length - 1 ? '+' : ''}
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TierSelection;
