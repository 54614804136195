import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { createContext, useEffect, useState } from 'react';

import { useGetTarochiSeasonPassMinted } from '../../../hooks/useGetTarochiSeasonPassMinted';
import { useGetTarochiSeasonPassUserMinted } from '../../../hooks/useGetTarochiSeasonPassUserMinted';
import { useInterval } from '../../../hooks/useInterval';
import { useWeb3Context } from '../../../hooks/useWeb3Context';
import {
  TAROCHI_GENESIS_NFT_MAX_SUPPLY,
  TAROCHI_SALE_MINT_END,
  TAROCHI_SALE_MINT_LIVE,
  TAROCHI_SALE_WHITELIST_END
} from '../../../services/constants';
import CookieConsentBanner from '../../CookieConsentBanner';
import Header from '../header/Header';
import CollectionIntro from './CollectionIntro';
import FAQ from './FAQ';
import Hero from './Hero';
import OrderNowButton from './OrderNowButton';
import Roadmap from './Roadmap';
import SeasonPassInfo from './SeasonPassInfo';
import TarochiInfo from './TarochiInfo';
import TarochiTimer from './TarochiTimer';
import TokenomicsInfo from './TokenomicsInfo';

export interface TarochiLayoutContextProps {
  minted: number | undefined;
  mintedUser: number | undefined;
  refreshMintedUser: (() => Promise<void>) | undefined;
}

export const TarochiLayoutContext = createContext<TarochiLayoutContextProps>({
  minted: undefined,
  mintedUser: undefined,
  refreshMintedUser: undefined
});

const TarochiLayout = () => {
  const { connected } = useWeb3Context();
  const { t } = useTranslation();

  const now = new Date();
  const { minted, refresh: refreshMinted } = useGetTarochiSeasonPassMinted();
  const { minted: mintedUser, refresh: refreshMintedUser } = useGetTarochiSeasonPassUserMinted(
    new Date().getTime() < TAROCHI_SALE_WHITELIST_END
  );

  const [intervalDelay, setIntervalDelay] = useState<number | null>(20000);
  useInterval(() => {
    if (new Date().getTime() > TAROCHI_SALE_MINT_LIVE) {
      refreshMinted();
    }
  }, intervalDelay);
  useEffect(() => {
    refreshMinted();
  }, []);

  useEffect(() => {
    if (minted != null && minted >= TAROCHI_GENESIS_NFT_MAX_SUPPLY) {
      setIntervalDelay(null);
    }
  }, [minted]);

  return (
    <TarochiLayoutContext.Provider value={{ minted, mintedUser, refreshMintedUser }}>
      <div className="relative font-base bg-engine-black pb-64">
        <Header connected={connected} />
        <CookieConsentBanner />
        <Hero />
        <TarochiTimer deadline={new Date(TAROCHI_SALE_MINT_LIVE)} label="Whitelist mint will begin in" />
        <TarochiTimer minted={minted} deadline={new Date(TAROCHI_SALE_WHITELIST_END)} label="Public mint will begin in" />
        {now > new Date(TAROCHI_SALE_WHITELIST_END) && (
          <TarochiTimer minted={minted} deadline={new Date(TAROCHI_SALE_MINT_END)} label="Mint will end in" />
        )}
        <TarochiInfo />
        <SeasonPassInfo />
        <TokenomicsInfo />
        <CollectionIntro />
        <Roadmap />
        <FAQ />
        <div className="flex mt-32 flex-col items-center">
          <OrderNowButton />
        </div>
      </div>
    </TarochiLayoutContext.Provider>
  );
};

export default TarochiLayout;
