import classNames from 'classnames';
import { BigNumber, ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils.js';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useIsTarochiGenesisSoldOut } from '../../../hooks/useIsTarochiGenesisSoldOut';
import { useIsTarochiGenesisSoldOutUser } from '../../../hooks/useIsTarochiGenesisSoldOutUser';
import { useWeb3Context } from '../../../hooks/useWeb3Context';
import { ADDRESS_ZERO } from '../../../services/constants';
import {
  approveTarochiSeasonPassErc20,
  buyTarochiSeasonPassErc20,
  buyTarochiSeasonPassNative,
  hasSufficientERC20Balance,
  hasSufficientNativeBalance,
  TarochiChain
} from '../../../services/contract';
import { truncateAddress, validEthAddress } from '../../../services/utils';
import VolcaneersButton from '../volcaneers/VolcaneersButton';
import { chains } from './ChainSelection';
import { Currency } from './CurrencySelection';
import { Tier } from './TierSelection';

const modalStyles: ReactModal.Styles = {
  overlay: {
    zIndex: '100',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(5px)',
    overflowY: 'scroll'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    border: 'none',
    marginTop: '150px'
  }
};

type Props = {
  continueStep: (hash: string) => void;
  cancelFlow: () => void;
  setCantCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
  tier: Tier;
  currency: Currency;
  chain: TarochiChain;
};

const OrderCheckout = ({ continueStep, cancelFlow, setCantCloseModal, tier, currency, chain }: Props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const [referral, setReferral] = useState(tier === 'tier1' ? '' : queryParameters.get('referral') ?? '');
  const { currentAccount } = useWeb3Context();
  const [approving, setApproving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [txHash, setTxHash] = useState<string>('');
  const [isBalanceSufficient, setIsBalanceSufficient] = useState<boolean>();
  const { soldOut: soldOutGlobal } = useIsTarochiGenesisSoldOut();
  const { soldOutUser } = useIsTarochiGenesisSoldOutUser();
  const soldOut = soldOutGlobal || (soldOutUser && chain !== TarochiChain.cardano);
  const [prevSoldOut, setPrevSoldOut] = useState(soldOut);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const invalidFormatReferralCode = referral.length > 0 && !validEthAddress(referral);
  const ownAddressReferralCode = chain !== TarochiChain.cardano && referral.toLowerCase() === currentAccount;
  const referralCodeErroneous = chain !== TarochiChain.cardano && isReferralCodeInvalid(referral);
  const referralCodeValid = referral.length > 0 && !referralCodeErroneous;
  const applyReferral = referralCodeValid && chain !== TarochiChain.cardano;

  let price = currency[tier];
  const tier2PriceAfterDiscount = soldOut
    ? BigNumber.from('0')
    : applyReferral
    ? currency.tier2.mul(9).div(10)
    : currency.tier2;
  if (tier === 'tier2') {
    price = tier2PriceAfterDiscount;
  } else if (tier === 'tier3') {
    price = currency.tier3.sub(currency.tier2).mul(5).add(tier2PriceAfterDiscount);
  }
  const [priceInputString, setPriceInputString] = useState<string>(
    ethers.utils.formatUnits(price, currency.tokenDecimals)
  );
  const [priceInputBN, setPriceInputBN] = useState(price);

  const { t } = useTranslation();

  useEffect(() => {
    if (soldOut != prevSoldOut) {
      setIsModalOpen(true);
      setPrevSoldOut(soldOut);
    }
  }, [soldOut]);

  useEffect(() => {
    async function fetchBalanceSufficiency() {
      if (priceInputBN == null) {
        return;
      }
      if (chain === TarochiChain.cardano) {
        setIsBalanceSufficient(true);
        return;
      }
      setIsBalanceSufficient(
        currency.tokenAddress === ADDRESS_ZERO
          ? await hasSufficientNativeBalance(currentAccount, priceInputBN)
          : await hasSufficientERC20Balance(currentAccount, currency.tokenAddress, priceInputBN)
      );
    }
    fetchBalanceSufficiency();
  }, [priceInputBN]);

  function isReferralCodeInvalid(referral: string) {
    const invalidFormatReferralCode = referral.length > 0 && !validEthAddress(referral);
    const ownAddressReferralCode = referral.toLowerCase() === currentAccount;
    return invalidFormatReferralCode || ownAddressReferralCode;
  }

  function refreshStaticPrice(referral: string) {
    setPriceInputBN(price);
  }

  async function buySeasonPass(referral: string) {
    if (!currency) {
      throw 'No selected currency';
    }
    if (!priceInputBN) {
      throw 'Price not loaded';
    }
    try {
      setIsLoading(true);
      console.log('price', ethers.utils.formatUnits(priceInputBN, currency.tokenDecimals));
      const tx =
        currency.tokenAddress === ADDRESS_ZERO
          ? await buyTarochiSeasonPassNative(currentAccount, priceInputBN, referral || undefined)
          : await buyTarochiSeasonPassErc20(currentAccount, priceInputBN, currency.tokenAddress, referral || undefined);
      setTxHash(tx.hash);
      setCantCloseModal(true);
      setIsLoading(false);
      setIsPending(true);

      await tx.wait();
      setIsPending(false);
      return tx.hash;
    } catch (err: any) {
      if (err.code === 'ACTION_REJECTED') {
        return;
      }
      throw err;
    } finally {
      setCantCloseModal(false);
      setIsLoading(false);
      setIsPending(false);
    }
  }

  async function approveErc20() {
    setApproving(true);
    try {
      setIsLoading(true);
      const tx = await approveTarochiSeasonPassErc20(currentAccount, currency.tokenAddress);
      setIsLoading(false);
      setApproving(false);
      setTxHash(tx.hash);
      setIsPending(true);
      await tx.wait();
      setIsPending(false);
    } catch (err: any) {
      if (err.code === 'ACTION_REJECTED') {
        return;
      }
      throw err;
    } finally {
      setApproving(false);
      setIsPending(false);
    }
  }

  async function priceInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    let newValueString = ev.target.value;
    try {
      const newValueBN = ethers.utils.parseUnits(newValueString === '' ? '0' : newValueString, currency.tokenDecimals);
      setPriceInputString(newValueString);
      setPriceInputBN(newValueBN);
    } catch (err) {}
  }

  async function referralInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    setReferral(ev.target.value);
  }

  useEffect(() => {
    if (tier !== 'tier3') {
      setPriceInputBN(price);
    }
  }, [referral]);

  async function handleClickOrder() {
    if (chain === TarochiChain.cardano) {
      window.open(
        `https://pay.nmkr.io/?receiverAddress=addr1v9glhp7wdxnfk24jq4gjjsry6st8pjk5d6q39ctn83qx8gs9aq4te&amount=${priceInputBN}&note=Tarochi`,
        '_blank',
        'noreferrer'
      );
    } else {
      try {
        setTxHash('');
        const hash = await buySeasonPass(referral);
        if (hash != null) {
          continueStep(hash);
        }
      } catch (err: any) {
        if (err.reason?.includes('insufficient allowance') || err.reason?.includes('exceeds allowance')) {
          await approveErc20();
          return;
        }
        throw err;
      }
    }
  }

  const priceInputErroneous = isBalanceSufficient === false;

  const genesisTrainerMinPrice = applyReferral ? currency.tier2.mul(9).div(10) : currency.tier2;
  const genesisTrainerPrice =
    !soldOut && priceInputBN.gte(genesisTrainerMinPrice) ? currency.tier2 : BigNumber.from('0');
  const genesisTrainerDiscount =
    !soldOut && applyReferral && !referralCodeErroneous ? genesisTrainerPrice.div(10) : BigNumber.from('0');
  const genesisTrainerPriceAfterDiscount = genesisTrainerPrice.sub(genesisTrainerDiscount);
  const genesisTrainerPlusMinPrice = soldOut
    ? currency.tier3.sub(currency.tier2)
    : currency.tier3.sub(currency.tier2).add(genesisTrainerMinPrice);
  const genesisTrainerPlusPrice = priceInputBN.gte(genesisTrainerPlusMinPrice)
    ? priceInputBN
        .sub(genesisTrainerPriceAfterDiscount)
        .div(currency.tier3.sub(currency.tier2))
        .mul(currency.tier3.sub(currency.tier2))
    : BigNumber.from('0');
  const tgold = genesisTrainerPlusPrice.div(currency.tier3.sub(currency.tier2)).mul(2560).toString();
  const total = genesisTrainerPriceAfterDiscount.add(genesisTrainerPlusPrice);

  function handleClickGenesisPlus() {
    let addition = currency.tier3.sub(currency.tier2);
    const newContribution = total.add(addition);
    setPriceInputBN(newContribution);
    setPriceInputString(ethers.utils.formatUnits(newContribution, currency.tokenDecimals));
  }

  return (
    <div className="flex flex-col text-white gap-64">
      <div className="flex font-heading">
        <div className="flex rounded-xl bg-[#B2BBC7]/10 max-w-[60%] p-16">
          <img
            src={'/images/tarochi-season-1-pass.png'}
            alt={`Tarochi Season 1 Pass`}
            className="w-full h-full max-w-[350px] md:w-[260px] md:h-[257px]"
          />
          <div
            className={classNames(
              'flex flex-col h-full items-center text-start',
              tier !== 'tier3' ? 'justify-center' : 'justify-between'
            )}
          >
            <p className="text-20 sm:text-32 md:text-40 font-bold px-16">
              <Trans>Tarochi Season 1 Season Pass</Trans>
            </p>
            {tier === 'tier3' && (
              <div className="flex flex-col w-full gap-4">
                <p>
                  <Trans>Contribution amount</Trans>
                </p>
                <input
                  type="text"
                  value={priceInputString}
                  onChange={priceInputChange}
                  placeholder={t('Enter your contribution')}
                  className={classNames(
                    'input-custom rounded-full placeholder:text-volcaneers-grey-default',
                    priceInputErroneous
                      ? 'text-red-500'
                      : priceInputString.length > 0
                      ? 'text-engine-black'
                      : 'text-engine-gray'
                  )}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-[1px] bg-primary-color/20 mx-28"></div>
        <div className="flex flex-col gap-32 flex-grow justify-between">
          <div className="flex flex-col gap-16">
            <div className="flex justify-between">
              <p>
                <Trans>Soulbound NFT</Trans>
              </p>
              <p>
                {formatUnits(0, currency.tokenDecimals)}&nbsp;{currency.tokenSymbol}
              </p>
            </div>
            {!soldOut && priceInputBN.gte(genesisTrainerMinPrice) && (
              <div className="flex justify-between">
                <p>
                  <Trans>Genesis Trainer</Trans>
                </p>
                <p>
                  {formatUnits(genesisTrainerPrice, currency.tokenDecimals)}&nbsp;{currency.tokenSymbol}
                </p>
              </div>
            )}
            {genesisTrainerDiscount.gt(0) && (
              <div className="flex justify-between text-primary-color">
                <p>
                  <Trans>10% discount</Trans>
                </p>
                <p>
                  -{formatUnits(genesisTrainerDiscount, currency.tokenDecimals)}&nbsp;{currency.tokenSymbol}
                </p>
              </div>
            )}
            {priceInputBN.gte(genesisTrainerPlusMinPrice) && (
              <div className="flex flex-col text-start">
                <div className="flex justify-between gap-16">
                  <div className="flex gap-8">
                    <button
                      onClick={handleClickGenesisPlus}
                      className="bg-volcaneers-primary-default hover:bg-volcaneers-primary-dark px-8 rounded-md"
                    >
                      +
                    </button>
                    <p>{tgold} $TGOLD</p>
                  </div>
                  <p>
                    {formatUnits(genesisTrainerPlusPrice, currency.tokenDecimals)}&nbsp;{currency.tokenSymbol}
                  </p>
                </div>
                <p className="text-13">
                  <Trans
                    i18nKey="2560 $TGOLD for each _price_ _symbol_ ($1) over Genesis Trainer"
                    values={{
                      _price_: formatUnits(currency.tier3.sub(currency.tier2), currency.tokenDecimals),
                      _symbol_: currency.tokenSymbol
                    }}
                  />
                </p>
              </div>
            )}
            <div className="flex justify-between">
              <p>
                <Trans>Total</Trans>
              </p>
              <p className="font-bold">
                {formatUnits(total, currency.tokenDecimals)}&nbsp;{currency.tokenSymbol}
              </p>
            </div>
          </div>
          {chain !== TarochiChain.cardano && (
            <div className="flex flex-col gap-8">
              {invalidFormatReferralCode && (
                <p className="text-start text-red-500">
                  <Trans>Invalid referral code!</Trans>
                </p>
              )}
              {ownAddressReferralCode && (
                <p className="text-start text-red-500">
                  <Trans>Cannot use your own referral code!</Trans>
                </p>
              )}
              {tier !== 'tier1' && (
                <div className="flex flex-col items-start gap-4">
                  <p>
                    <Trans>Referral code</Trans>
                  </p>
                  <input
                    type="text"
                    value={referral}
                    onChange={referralInputChange}
                    placeholder={t('Enter referral code')}
                    className={classNames(
                      'input-custom rounded-full placeholder:text-volcaneers-grey-default',
                      referralCodeErroneous
                        ? 'text-red-500'
                        : referral.length > 0
                        ? 'text-engine-black'
                        : 'text-engine-gray'
                    )}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center gap-8">
        {txHash !== '' && (
          <p className="text-start text-16 md:text-20 lg:text-28 font-heading">
            <Trans>Transaction hash:</Trans>{' '}
            <a href={`${chains[chain].explorerTxUrl}/${txHash}`} className="hover:underline break-all" target="_blank">
              {truncateAddress(txHash)}
            </a>
          </p>
        )}
        {approving && <p className="text-start text-16 md:text-20 lg:text-28">Approve ERC20 token spending</p>}
        <VolcaneersButton
          sizeVariant="small"
          onClick={handleClickOrder}
          uppercaseText={false}
          disabled={
            !isBalanceSufficient || referralCodeErroneous || isLoading || isPending || priceInputBN == null || approving
          }
        >
          {isBalanceSufficient == null ? (
            <Trans>Loading...</Trans>
          ) : !isBalanceSufficient ? (
            <Trans>Insufficient balance</Trans>
          ) : isLoading ? (
            <Trans>Confirm transaction</Trans>
          ) : isPending ? (
            <Trans>Pending confirmation...</Trans>
          ) : (
            <Trans>Complete Order</Trans>
          )}
        </VolcaneersButton>
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          cancelFlow();
        }}
        style={modalStyles}
        contentLabel="Genesis Trainer Sold Out alert modal"
        ariaHideApp={false}
      >
        <div className="tarochi-blur-modal flex flex-col items-center gap-32 font-heading text-16 sm:text-32 md:text-40 font-bold text-white">
          <p>
            <Trans>Genesis Trainer just sold out!</Trans>
          </p>
          <p>
            <Trans>Please start again and choose a different tier.</Trans>
          </p>
          <VolcaneersButton
            onClick={() => {
              setIsModalOpen(false);
              cancelFlow();
            }}
          >
            <Trans>OK</Trans>
          </VolcaneersButton>
        </div>
      </ReactModal>
    </div>
  );
};

export default OrderCheckout;
