import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { useIsTarochiGenesisSoldOut } from '../../../hooks/useIsTarochiGenesisSoldOut';
import { useTarochiLayoutContext } from '../../../hooks/useTarochiLayoutContext';
import { useWeb3Context } from '../../../hooks/useWeb3Context';
import { TAROCHI_SALE_MINT_END, TAROCHI_SALE_MINT_LIVE } from '../../../services/constants';
import { TarochiChain } from '../../../services/contract';
import { Spinner } from '../../Spinner';
import VolcaneersButton from '../volcaneers/VolcaneersButton';
import ChainSelection, { chains } from './ChainSelection';
import CurrencySelection, { currencies, Currency } from './CurrencySelection';
import OrderCheckout from './OrderCheckout';
import OrderComplete from './OrderComplete';
import TierSelection, { Tier } from './TierSelection';

export const modalStyles: ReactModal.Styles = {
  overlay: {
    zIndex: '100',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(5px)',
    overflowY: 'scroll'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    border: 'none',
    marginTop: '150px'
  }
};

enum Steps {
  SELECT_CHAIN,
  SELECT_TIER,
  SELECT_CURRENCY,
  ORDER_CHECKOUT,
  ORDER_COMPLETE
}

const OrderNowButton = () => {
  const now = new Date();
  if (now > new Date(TAROCHI_SALE_MINT_END)) {
    return <></>;
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cantCloseModal, setCantCloseModal] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedTier, setSelectedTier] = useState<Tier>();
  const [selectedChain, setSelectedChain] = useState<TarochiChain>();
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>();
  const [txHash, setTxHash] = useState<string>('');
  const { chainId, currentAccount, connected, connectWallet, disconnectWallet } = useWeb3Context();
  const { soldOut, loading: soldOutLoading } = useIsTarochiGenesisSoldOut();
  const { refreshMintedUser } = useTarochiLayoutContext();

  const buttonLoading = soldOutLoading;
  const mintNotLive = now.getTime() < TAROCHI_SALE_MINT_LIVE;
  const buttonDisabled = mintNotLive || buttonLoading;

  async function buttonClicked() {
    if (buttonDisabled) {
      return;
    }
    setIsModalOpen(true);
  }

  function cancelFlow() {
    setIsModalOpen(false);
    setStep(0);
    setSelectedTier(undefined);
    setSelectedChain(undefined);
    setSelectedCurrency(undefined);
  }

  return (
    <>
      <VolcaneersButton sizeVariant="small" uppercaseText={false} onClick={buttonClicked} disabled={buttonDisabled}>
        {mintNotLive ? <Trans>Coming Soon</Trans> : buttonLoading ? <Spinner /> : <Trans>Order Now</Trans>}
      </VolcaneersButton>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => {
          if (!cantCloseModal) {
            cancelFlow();
          }
        }}
        style={modalStyles}
        contentLabel="Architecture Modal"
        ariaHideApp={false}
      >
        <div className="tarochi-blur-modal">
          <div className="flex flex-col">
            <div className="flex gap-16 items-center mb-24 sm:mb-50">
              <img src={'/images/tarochi.webp'} alt={`Tarochi logo`} className="max-w-[88px] max-h-[88px]" />
              <p className="font-heading text-16 sm:text-32 md:text-40 font-bold text-white">
                {step === Steps.ORDER_COMPLETE ? (
                  <Trans>Order completed!</Trans>
                ) : (
                  <>
                    <Trans>Order now</Trans> &middot; <Trans>Tarochi season pass</Trans>
                  </>
                )}
              </p>
            </div>
            {step === Steps.SELECT_CHAIN ? (
              <ChainSelection
                continueStep={async (chain) => {
                  setStep(step + 1);
                  setSelectedChain(chain);
                }}
              />
            ) : step === Steps.SELECT_TIER ? (
              <TierSelection
                genesisSoldOut={soldOut}
                chain={selectedChain!}
                continueStep={async (tier) => {
                  setStep(step + (tier === 'tier1' || selectedChain === TarochiChain.cardano ? 2 : 1));
                  setSelectedTier(tier);
                }}
              />
            ) : selectedChain !== TarochiChain.cardano && chains[selectedChain!].chainId !== chainId ? (
              <p className="text-white text-40">Network change detected, please start over.</p>
            ) : step === Steps.SELECT_CURRENCY ? (
              <CurrencySelection
                selectedChain={selectedChain!}
                selectedTier={selectedTier!}
                continueStep={(currency) => {
                  setStep(step + 1);
                  setSelectedCurrency(currency);
                }}
              />
            ) : step === Steps.ORDER_CHECKOUT ? (
              <OrderCheckout
                tier={selectedTier!}
                currency={selectedCurrency ?? currencies[selectedChain!][0]}
                chain={selectedChain!}
                setCantCloseModal={setCantCloseModal}
                continueStep={(hash) => {
                  setTxHash(hash);
                  setStep(step + 1);
                  refreshMintedUser?.();
                }}
                cancelFlow={cancelFlow}
              />
            ) : (
              <OrderComplete hash={txHash} chain={selectedChain!} tier={selectedTier!} />
            )}
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default OrderNowButton;
