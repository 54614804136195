import React from 'react';
import { Trans } from 'react-i18next';
import OrderNowButton from './OrderNowButton';
import VolcaneersLinkButton from '../volcaneers/VolcaneersLinkButton';

const details = [
  {
    iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
    title: <Trans>Full control over your assets</Trans>,
    description: (
      <>
        <p>
          <Trans>
            Every monster you catch isn't just a game asset; it's a unique Stateful NFT, evolving with every in-game
            action. And not just monsters either - all game state is uniquely owned by you and tradeable anytime.
          </Trans>
        </p>
      </>
    ),
    imageUrl: '/images/paima-tarochi-info-1.jpg'
  },
  {
    iconUrl: '/images/paima-engine-game-tab-icon-physics.svg',
    title: <Trans>Free and smooth onchain experience</Trans>,
    description: (
      <>
        <p>
          <Trans>
            Start playing Tarochi right away for free without having to connect any wallet Tarochi will create a wallet
            for you, and at any point you can transfer your game data to your main wallet. No bridging required.
          </Trans>
        </p>
      </>
    ),
    imageUrl: '/images/paima-tarochi-info-2.png'
  }
];

const TarochiInfo = () => {
  return (
    <section id="tarochi-info" className="relative flex flex-col items-center py-24 sm:py-32 md:py-64 mx-16">
      <div className="tarochi-blur-box max-w-7xl">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <div className="flex gap-16 items-center">
              <img src={'/images/tarochi.webp'} alt={`Tarochi logo`} className="max-w-[88px] max-h-[88px]" />
              <p className="font-heading text-24 sm:text-28 md:text-40 font-bold text-white text-start">
                <Trans>What is Tarochi</Trans>
              </p>
            </div>
            <div className="mt-48 text-engine-gray flex flex-col items-start text-start">
              <p>
                <Trans>
                  Tarochi is an ambitious onchain RPG, where every quest, every challenge, and every monster caught
                  becomes a part of the blockchain history. Journey through a vast, immersive land, interacting with
                  NPCs, unlocking achievements, and unraveling quests.
                </Trans>
              </p>
              <br />
              <p>
                <Trans>
                  Dive into this revolutionary experience on the Xai network, made seamless by Arbitrum Orbit and the
                  powerful Paima Engine. Best of all? Embark on this adventure without the hassle of bridging, and
                  kickstart your legend for free!
                </Trans>
              </p>
            </div>
            <div className="flex flex-col flex-grow items-stretch justify-end md:items-start mt-16">
              <OrderNowButton />
              <VolcaneersLinkButton
                  sizeVariant="small"
                  uppercaseText={false}
                  href="https://tarochi.paimastudios.com"
                >
                  Start Your Adventure
              </VolcaneersLinkButton>
            </div>
          </div>
        </div>
        <div className="mt-48 flex flex-col md:flex-row gap-24 text-start">
          {details.map((detail, index) => (
            <div key={index} className="flex flex-col md:w-1/2">
              <div className="flex-1">
                <h3 className="flex items-start gap-8 text-20 leading-6 text-white font-bold">
                  <img src={detail.iconUrl} alt="" className="w-[24px] h-[24px] relative" />
                  {detail.title}
                </h3>
                <div className="text-engine-gray mt-24 text-start">{detail.description}</div>
              </div>
              <img src={detail.imageUrl} alt="" className="mt-24 lg:mt-40" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TarochiInfo;
