import { useEffect, useRef } from 'react';

export const useInterval = (callback: Function, delay: number | null) => {
  const savedCallback = useRef<Function>();
  // Remember the last callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set the interval
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (delay != null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
};
