import React from 'react';
import { Trans } from 'react-i18next';
import OrderNowButton from './OrderNowButton';

const details = [
  {
    iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
    title: <Trans>Tarochi Monsters</Trans>,
    description: (
      <>
        <p>
          <Trans>
            Every wild area in Tarochi contains monsters that you can battle for experience points or to capture.
            Monsters come in a variety of rarity:
          </Trans>
        </p>
        <ul className="list-disc ml-24 text-start">
          <li>
            <Trans
              i18nKey="Common and Uncommon: unlimited supply (for F2P players)"
              components={{
                strong: <strong />,
                em: <em />
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="Rare, Epic, Legendary: global daily supply limit between players"
              components={{
                strong: <strong />,
                em: <em />
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="Limited Edition: can only be caught during a limited-time campaign"
              components={{
                strong: <strong />,
                em: <em />
              }}
            />
          </li>
        </ul>
        <br />
        <p>
          <Trans>
            This mix means there are always monsters for you to fight no matter when you login, and there is always a
            chance for free-to-play players to catch rare monsters.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>Notably, respawn rates are as follows:</Trans>
        </p>
        <ul className="list-disc ml-24 text-start">
          <li>
            <Trans>Rare: every hour</Trans>
          </li>
          <li>
            <Trans>Epic: every 4hrs</Trans>
          </li>
          <li>
            <Trans>Legendary: every 17hrs</Trans>
          </li>
        </ul>
        <br />
        <p>
          <Trans
            i18nKey="The basic capture card is always free to purchase at the store, but has a chance at failing to capture a monster. To overcome this, there are whitelist cards that you can purchase with Tarochi Gold:"
            components={{
              strong: <strong />,
              em: <em />
            }}
          />
        </p>
        <ul className="list-disc ml-24 text-start">
          <li>
            <Trans
              i18nKey="Common & Uncommon Whitelist Cards: similar to the standard capture card, but are guaranteed to capture the monster"
              components={{
                strong: <strong />,
                em: <em />
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="Rare+ Whitelist Cards: allow you to encounter monsters for the corresponding type (ex: epic monsters) even if the supply for that day has expired, and are guaranteed success if used"
              components={{
                strong: <strong />,
                em: <em />
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="Limited Edition Whitelist Cards: for promotional campaigns, users who qualify earn a whitelist card to capture the limited-edition monster for free (ex: holders of the underlying NFT project we are collaborating with). Additionally, season pass holders are also whitelisted. Others can purchase a whitelist card from the store"
              components={{
                strong: <strong />,
                em: <em />
              }}
            />
          </li>
        </ul>
        <br />
        <p>
          <Trans>The planned cost for these whitelist cards are:</Trans>
        </p>
        <ul className="list-disc ml-24 text-start">
          <li>
            <Trans>Common: 50 Tarochi Gold</Trans>
          </li>
          <li>
            <Trans>Uncommon: 100 Tarochi Gold</Trans>
          </li>
          <li>
            <Trans>Rare: 1,100 Tarochi Gold</Trans>
          </li>
          <li>
            <Trans>Epic: 4,500 Tarochi Gold</Trans>
          </li>
          <li>
            <Trans>Legendary: 19,200 Tarochi Gold</Trans>
          </li>
        </ul>
        <br />
        <br />
        <p>
          <Trans>
            With the exception of Limited Edition monsters, each monster has a soft-cap on its level based on its
            rarity. Once the monster reaches the soft-cap level, it can only be leveled up by fusing copies of the same
            monster into it. This means even monsters you already own are still valuable, and acts as a deflationary
            pressure on the new limited supply monsters that appear daily.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            You can read more about monster trading in the <a className="underline" href="https://tarochi.fandom.com/wiki/Monster_Trading">wiki</a>
          </Trans>
        </p>
      </>
    ),
    imageElement: (
      <div className="flex flex-col w-full h-full justify-between gap-32">
        <img src="/images/paima-tarochi-tokenomics-1.png" />
        <img src="/images/paima-tarochi-tokenomics-8.png" />
        <img src="/images/paima-tarochi-tokenomics-2.png" />
        <img src="/images/paima-tarochi-tokenomics-9.png" />
        <img src="/images/paima-tarochi-tokenomics-3.png" />
      </div>
    )
  },
  {
    iconUrl: '/images/paima-engine-game-tab-icon-physics.svg',
    title: <Trans>Tarochi Gold</Trans>,
    description: (
      <>
        <Trans>Tarochi features Tarochi Gold (TGold) as the onchain currency for the world.</Trans>
        &nbsp;
        <Trans
          i18nKey="Tarochi Gold is earned from competitive actions like participating in Arena battles against other players. Tarochi gold has a limited yet growing supply, and allows players to purchase whitelist cards to have a significant edge in capturing rare monsters. This IS meant to have financial value on the open market as it has a limited growth in supply and will trade starting with Arbitrum One."
          components={{
            strong: <strong />,
            em: <em />
          }}
        />
        &nbsp;
        <Trans
          i18nKey="The initial supply of TGold will come purely from this pre-order + $3770 worth of TGold given to the top players of Jungle Wars: Tower Defense."
          components={{
            strong: <strong />,
            em: <em />
          }}
        />
        <br />
        <br />
        <p>
          <Trans
            i18nKey="Notably, the primary way to earn Tarochi Gold is to participate in Arena battles. Arena battles are a type of PVP where users do not all have to online at the same time. Instead, there are multiple arena matches going on in parallel continuously, and the champion of the arena at any given point earns Tarochi Gold (with participants who are not champions earning Tarochi Silver instead). To semi-active play, the arena is reset every 24hrs. When you battle a player in the arena, you are battles against an AI that controls their monsters, allowing multiple battles against the reigning champion to happen in parallel. The player with the highest score (defined by how well you did) by the end of the rotation becomes the new champion for that slot. Think of it like a fast-paced best player wins tournament. Our goal is to encourage a social fabric such as guilds working together and pooling rewards or players renting out monsters in exchange for a cut of any arena rewards."
            components={{
              strong: <strong />,
              em: <em />
            }}
          />
        </p>
        <br />
        <p>
          <Trans
            i18nKey="The sum of all daily Arena rewards is 38,400 Tarochi Gold - the cost of 2 legendary whitelist capture card, but is subject to balancing."
            components={{
              strong: <strong />,
              em: <em />
            }}
          />
        </p>
        <br />
        <br />
        <p>
          <Trans
            i18nKey="If you prefer to battle players directly, you can also send PVP requests to real players (either without stakes, or by betting Tarochi Gold that you will win)."
            components={{
              strong: <strong />,
              em: <em />
            }}
          />
        </p>
        <br />
        <p>
          <Trans>
            You can read more about TGold in the <a className="underline" href="https://tarochi.fandom.com/wiki/Tarochi_Gold">wiki</a>
          </Trans>
        </p>
      </>
    ),
    imageElement: (
      <div className="flex flex-col w-full h-full justify-between gap-32">
        <img src="/images/paima-tarochi-tokenomics-4.png" />
        <img src="/images/paima-tarochi-tokenomics-5.png" />
        <img src="/images/paima-tarochi-tokenomics-6.png" />
      </div>
    )
  },
  {
    iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
    title: <Trans>Guilds</Trans>,
    description: (
      <>
        <p>
          <Trans>
            Tarochi features the ability to create guilds for 2000 TGold.
            Guilds have access to guild game modes like tournament battles that comes with TGold rewards for the winners.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            Guilds come with initial limitations (ex: limited number of members and monster slots), but upgrades can be purchased after leveling up. Three key resources play a role in this:
          </Trans>
          <ul className="list-disc ml-24 text-start">
            <li>
              <Trans
                i18nKey="<strong>XP</strong>: tax guild members so part of their XP goes towards leveling up the guild."
                components={{
                  strong: <strong />,
                  em: <em />
                }}
              />
            </li>
            <li>
              <Trans
                i18nKey="<strong>TGold</strong>: pay for upgrades once you reach the required level."
                components={{
                  strong: <strong />,
                  em: <em />
                }}
              />
            </li>
            <li>
              <Trans
                i18nKey="<strong>Rare+ monsters</strong>: increase your guild's max lvl by sacrificing them."
                components={{
                  strong: <strong />,
                  em: <em />
                }}
              />
            </li>
          </ul>
          <br />
          <Trans>
          Additionally, Genesis Trainers play a critical role in empowering guilds, as trainers held by guild members upgrade the guild's <i>Guild Genesis Trainers (GGT)</i> based on rarity. GGT increases the rewards your guild gets from winning guild game modes and helps increase notoriety of your guild by making your guild more visible in-game through icons, banners and more. 
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            You can read more about guilds in the <a className="underline" href="https://tarochi.fandom.com/wiki/Guilds">wiki</a>
          </Trans>
        </p>
      </>
    ),
    imageElement: (
      <div className="flex flex-col w-full h-full justify-between gap-32">
        <img src="/images/paima-tarochi-tokenomics-10.png" />
        <img src="/images/paima-tarochi-tokenomics-11.jpg" />
      </div>
    )
  },
  {
    iconUrl: '/images/paima-engine-game-tab-icon-boxes.svg',
    title: <Trans>Paima Ecosystem Token</Trans>,
    description: (
      <>
        <p>
          <Trans>
            Tarochi is built using Paima Engine a framework to build decentralized autonomous worlds. Paima Studios has
            created multiple games using the engine, Tarochi being our 5th game deployed to mainnet.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>
            Our team not only plans to continue working on Tarochi and future titles, but also plans to build out the
            Paima Engine ecosystem where the Paima Ecosystem Token will play a key role in operating any shared
            infrastructure (sequencers, data availability, storage, etc.), and will also be used to encourage player and
            developer engagement in the ecosystem and the operation of the Paima Builder DAO.
          </Trans>
        </p>
        <br />
        <p>
          <Trans
            i18nKey="You can read more about Paima Engine here, with a full whitepaper on the Paima Ecosystem token coming soon."
            components={{
              a: <a href="/paima-engine" className="underline" />
            }}
          />
        </p>
      </>
    ),
    imageElement: (
      <div className="flex flex-col w-full h-full justify-between gap-32">
        <img src="/images/paima-tarochi-tokenomics-7.png" />
      </div>
    )
  }
];

const TokenomicsInfo = () => {
  return (
    <section id="tokenomics" className="relative flex flex-col items-center py-24 sm:py-32 md:py-64 mx-16">
      <div className="tarochi-blur-box max-w-7xl">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <div className="flex gap-16 items-center">
              <img src={'/images/tarochi.webp'} alt={`Tarochi logo`} className="max-w-[88px] max-h-[88px]" />
              <p className="font-heading text-24 sm:text-28 md:text-40 font-bold text-white text-start">
                <Trans>Tarochi Tokenomics</Trans>
              </p>
            </div>
            <div className="mt-48 text-engine-gray flex flex-col items-start text-start">
              <p>
                <Trans>Tarochi comes with three primary components to its tokenomics model:</Trans>
              </p>
              <ul className="list-disc ml-24 text-start">
                <li>
                  <Trans>
                    Tarochi Monsters: tradeable as NFTs including limited-time monsters, supply-limited monsters and
                    unlimited supply free-to-play monsters
                  </Trans>
                </li>
                <li>
                  <Trans>
                    Tarochi Gold: an in-game currency earned through competing against other players globally
                  </Trans>
                </li>
                <li>
                  <Trans
                    i18nKey="Paima ecosystem token: a token that will power the Paima ecosystem across games (whitepaper pending)"
                    components={{
                      strong: <strong />,
                      em: <em />
                    }}
                  />
                </li>
              </ul>
            </div>
            <div className="flex flex-col flex-grow items-stretch justify-end md:items-start mt-16">
              <OrderNowButton />
            </div>
          </div>
        </div>
        <div className="mt-48 flex flex-col gap-24 md:gap-64 text-start">
          {details.map((detail, index) => {
            return (
              <div key={index} className="flex flex-col gap-32 md:flex-row md:gap-64">
                {index % 2 !== 0 && <div className="hidden md:w-1/2 md:block">{detail.imageElement}</div>}
                <div className="flex flex-col md:w-1/2">
                  <h3 className="flex items-start gap-8 text-20 leading-6 text-white font-bold">
                    <img src={detail.iconUrl} alt="" className="w-[24px] h-[24px] relative" />
                    {detail.title}
                  </h3>
                  <div className="text-engine-gray mt-24 text-start">{detail.description}</div>
                </div>
                {index % 2 === 0 && <div className="hidden md:w-1/2 md:block">{detail.imageElement}</div>}
                <div className="md:hidden">{detail.imageElement}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TokenomicsInfo;
