import classNames from 'classnames';
import React from 'react';

export interface VolcaneersLinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  sizeVariant?: 'regular' | 'small';
  outlineVariant?: boolean;
  uppercaseText?: boolean;
  invertColor?: boolean,
  overrideStyleClassnames?: string;
}

const displayTailwindClasses = [
  'block',
  'inline-block',
  'inline',
  'flex',
  'inline-flex',
  'table',
  'inline-table',
  'table-caption',
  'table-cell',
  'table-column',
  'table-column-group',
  'table-footer-group',
  'table-header-group',
  'table-row-group',
  'table-row',
  'flow-root',
  'grid',
  'inline-grid',
  'contents',
  'list-item',
  'hidden'
];

const VolcaneersLinkButton = ({
  uppercaseText = true,
  outlineVariant = false,
  sizeVariant,
  overrideStyleClassnames,
  invertColor = false,
  ...props
}: VolcaneersLinkButtonProps) => {
  const hasCustomDisplayClass =
    !!props.className && displayTailwindClasses.find((className) => props.className?.indexOf(className) !== -1);

  const color = invertColor ? "white" : "black";
  return (
    <a
      {...props}
      className={classNames(
        overrideStyleClassnames
          ? overrideStyleClassnames
          : [
              outlineVariant
                ? [
                    `border border-volcaneers-${color} text-volcaneers-${color} hover:border-volcaneers-primary-default hover:bg-volcaneers-primary-default hover:text-white`
                  ]
                : ['bg-volcaneers-primary-default hover:bg-volcaneers-primary-dark text-white'],
              uppercaseText && 'uppercase',
              'rounded-[115px]',
              'text-center',
              'disabled:bg-volcaneers-separator',
              'disabled:cursor-not-allowed',
              'disabled:bg-volcaneers-grey',
              'hover:cursor-pointer',
              !hasCustomDisplayClass && 'inline-block',
              sizeVariant === 'small' ? 'py-20 text-16 leading-5' : 'py-32 text-18 leading-6',
              sizeVariant === 'small' ? 'px-24' : 'px-24 md:px-44',
              sizeVariant === 'small' ? 'font-normal' : 'font-bold',
              props.className
            ]
      )}
    />
  );
};

export default VolcaneersLinkButton;
