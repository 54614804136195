import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';
import OrderNowButton from './OrderNowButton';

const Hero = () => {
  return (
    <div>
      <div className="relative flex flex-col items-center gap-8 md:gap-20 lg:gap-24 z-10">
        <div className="text-center">
          <h1 className="text-56 leading-[40px] sm:text-96 sm:leading-[70px] md:text-[150px] lg:text-[256px] lg:leading-[150px] font-medium text-white md:max-w-[700px] font-tarochi uppercase">
            <Trans>Tarochi</Trans>
          </h1>
          <h2 className="text-20 leading-[16px] sm:text-36 sm:leading-[36px] md:text-48 md:leading-[40px] lg:text-72 lg:leading-[63px] font-medium text-white md:max-w-[700px] font-tarochi">
            <Trans>Monster Chronicles</Trans>
          </h2>
        </div>
        <OrderNowButton />

        <h3 className="text-20 sm:text-28 md:text-32 lg:text-48 text-center font-medium text-white md:max-w-[700px] font-tarochi uppercase">
          <Trans>An onchain RPG built by Paima Studios</Trans>
        </h3>
      </div>
      <div className="absolute left-0 right-0 top-[200px] w-full pointer-events-none">
        <img src="/images/paima-engine-game-tabs-line-bg-optimized.png" alt="" />
      </div>
    </div>
  );
};

export default Hero;
