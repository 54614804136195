import { TAROCHI_SALE_WHITELIST_END } from '../services/constants';
import { useTarochiLayoutContext } from './useTarochiLayoutContext';

export const useIsTarochiGenesisSoldOutUser = () => {
  const { mintedUser } = useTarochiLayoutContext();

  const now = new Date().getTime();
  return {
    soldOutUser: mintedUser != null && mintedUser >= 3 && now < TAROCHI_SALE_WHITELIST_END,
    loading: mintedUser == null && now < TAROCHI_SALE_WHITELIST_END
  };
};
