import React from 'react';
import { Trans } from 'react-i18next';
import OrderNowButton from './OrderNowButton';

const details = [
  {
    iconUrl: '/images/paima-engine-game-tab-icon-graphic.svg',
    title: <Trans>Collection layout</Trans>,
    description: (
      <>
        <p>
          <Trans>
            Genesis Trainers are capped at a 10,000 supply limit. Every purchase of the <i>Genesis Trainer</i> tier will get you an additional NFT once the pre-order is complete.
          </Trans>
        </p>
        <br />
        <p>
          <Trans>Tarochi is based on <a href="https://en.wikipedia.org/wiki/Tarot">Tarot Cards</a>, and so each card has the following traits:</Trans>
        </p>
        <ul className="list-disc ml-24 text-start">
          <li>
            <Trans><b>Trainer:</b> one of 48 possible trainers featured on the card</Trans>
          </li>
          <li>
            <Trans><b>Suit:</b> the trainer will be colored red, blue, yellow, green of grey. These are based on the 4 <a href="https://en.wikipedia.org/wiki/Minor_Arcana">Minor Arcana</a> of tarot cards. Based on Tarochi lore, monster types are based on the color associated with the element of each minor arcana. Notably, Wands <i>(Fire)</i> are red, Cups <i>(Water)</i> are blue, Swords <i>(Air)</i> are yellow, Pentacles <i>(Earth)</i> are green and skeptics who don't believe in tarot cards are grey.</Trans>
          </li>
          <li>
            <Trans><b>Frame</b>: every card is placed inside a frame representing rarity (the same frames used for monster rarity in-game. Learn more about monster rarity in the <i>Tarochi Tokenomics</i> section). Red is common, purple is uncommon, blue is rare, green is epic and yellow is legendary.</Trans>
          </li>
          <li>
            <Trans><b>Rank</b>: each card has a rank from 1 to 10, following by <i>page</i>, <i>knight</i>, <i>queen</i>, <i>king</i> forming 14 possibilities <i>(note: this is different than the 13 in standard 52-card decks)</i></Trans>
          </li>
        </ul>
        <br />
        <p>
          <Trans>For every increase in rarity of the frame, one rank is removed per trainer. <i>(ex: for the golden frame, there will only be one rank 3 for a given trainer)</i>. To round it down to 10,000 NFTs, 80 red frame cards will be removed at random</Trans>
        </p>
        <br />
        <p>
          <Trans>This gives the following distribution:</Trans>
          <div className="flex justify-center items-center">
            <table className="border-2 table-fixed w-full max-w-[500px] text-center">
              <thead>
                <tr className='border-y-1'>
                  <th>Frame</th>
                  <th>Card count</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Red Frame</td>
                  <td>3280 Cards</td>
                </tr>
                <tr>
                  <td>Purple Frame</td>
                  <td>2688 Cards</td>
                </tr>
                <tr>
                  <td>Blue Frame</td>
                  <td>2016 Cards</td>
                </tr>
                <tr>
                  <td>Green Frame</td>
                  <td>1344 Cards</td>
                </tr>
                <tr>
                  <td>Yellow Frame</td>
                  <td>672 Cards</td>
                </tr>
              </tbody>
            </table>
          </div>
        </p>
        <br />
        <p>
            <Trans>The visual rarity of these cards does not impact gameplay (at least initially) and all cards regardless of rarity receive the Genesis Trainer perks</Trans>
        </p>
      </>
    ),
  }
];

const CollectionIntro = () => {
  return (
    <section id="tokenomics" className="relative flex flex-col items-center py-24 sm:py-32 md:py-64 mx-16">
      <div className="tarochi-blur-box max-w-7xl">
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <div className="flex gap-16 items-center">
              <img src={'/images/tarochi.webp'} alt={`Tarochi logo`} className="max-w-[88px] max-h-[88px]" />
              <p className="font-heading text-24 sm:text-28 md:text-40 font-bold text-white text-start">
                <Trans>Genesis Trainer Collection</Trans>
              </p>
            </div>
            <div className="mt-48 text-engine-gray flex flex-col items-start text-start">
              <p>
                <Trans>Pre-ordering for the Genesis Trainer tier gives you a Genesis Trainer NFT which will sent to your wallet once the pre-order is complete and provides multiple benefits including whitelist slots for limited-edition monsters, loyalty rewards and accelerated progress in-game (see <i>Tarochi season pass</i> section above for more information).</Trans>
              </p>
            </div>
            <div className="flex flex-col flex-grow items-stretch justify-end md:items-start mt-16">
              <OrderNowButton />
            </div>
          </div>
        </div>
        <div className="mt-48 flex flex-col gap-24 md:gap-64 text-start">
          {details.map((detail, index) => {
            return (
              <div key={index} className="flex flex-col gap-32 md:flex-row md:gap-64">
                <div className="flex flex-col">
                  <h3 className="flex items-start gap-8 text-20 leading-6 text-white font-bold">
                    <img src={detail.iconUrl} alt="" className="w-[24px] h-[24px] relative" />
                    {detail.title}
                  </h3>
                  <div className="text-engine-gray mt-24 text-start">{detail.description}</div>
                </div>
              </div>
            );
          })}
          <div className="flex flex-row flex-wrap justify-between w-full gap-y-32 gap-x-8 text-16">
            <img src='/images/card1.png' alt=""  />
            <img src='/images/card2.png' alt=""  />
            <img src='/images/card3.png' alt=""  />
            <img src='/images/card4.png' alt=""  />
        </div>
        </div>
      </div>
    </section>
  );
};

export default CollectionIntro;
