import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Layout from '../../components/v2/Layout';
import TarochiLayout from '../../components/v2/tarochi/TarochiLayout';

const Tarochi = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Tarochi')} mainWrapperClass="bg-engine-black">
      <TarochiLayout />
    </Layout>
  );
};

export default Tarochi;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
